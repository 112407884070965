import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Dropdown from "components/Dropdown";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>

    <Playground __position={0} __code={'() => {\n  const [dropdownOpen, setDropdownOpen] = React.useState(false)\n  const toggle = () => setDropdownOpen(prevState => !prevState)\n  return (\n    <Dropdown isOpen={dropdownOpen} toggle={toggle}>\n      <Dropdown.Toggle caret>Dropdown</Dropdown.Toggle>\n      <Dropdown.Menu>\n        <Dropdown.Item header>Header</Dropdown.Item>\n        <Dropdown.Item>Some Action</Dropdown.Item>\n        <Dropdown.Item text>Dropdown Item Text</Dropdown.Item>\n        <Dropdown.Item disabled>Action (disabled)</Dropdown.Item>\n        <Dropdown.Item divider />\n        <Dropdown.Item>Foo Action</Dropdown.Item>\n        <Dropdown.Item>Bar Action</Dropdown.Item>\n        <Dropdown.Item>Quo Action</Dropdown.Item>\n      </Dropdown.Menu>\n    </Dropdown>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Dropdown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);

        const toggle = () => setDropdownOpen(prevState => !prevState);

        return <Dropdown isOpen={dropdownOpen} toggle={toggle} mdxType="Dropdown">
        <Dropdown.Toggle caret>
          Dropdown
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item header>Header</Dropdown.Item>
          <Dropdown.Item>Some Action</Dropdown.Item>
          <Dropdown.Item text>Dropdown Item Text</Dropdown.Item>
          <Dropdown.Item disabled>Action (disabled)</Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item>Foo Action</Dropdown.Item>
          <Dropdown.Item>Bar Action</Dropdown.Item>
          <Dropdown.Item>Quo Action</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p>
  <a href="https://reactstrap.github.io/components/dropdowns/" target="_blank">Reactstrap Dropdown</a>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      